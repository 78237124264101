/* you are */
.three-card-flex{
    display: flex;
    flex-direction: row;
}
.three-card-height{
    height:518px;
}
.three-card-hide{
}
.font-24-16{
    font-size: 24px;
}
.font-14-10{
    font-size: 14px;
}
.font-12-10{
    font-size: 12px;
}
.font-48-24{
    font-size: 48px;
}
.font-18-12{
    font-size: 18px;
}
.font-16-12{
    font-size: 16px;
}
.mobile-show{
    display: none;
}
@media only screen and (max-width: 1024px) {
    .three-card-flex{
        flex-direction: column;
    }
    .three-card-height{
        height:400px
    }
    .not-selected{
        height: auto !important;
    }
    .three-card-hide{
        display: none;
    }
    .mobile-show{
        display: flex;
    }
    .smaller-card{
        height: 400px;
    }
    .just-center{
        justify-content: center;
    }
    .font-24-16{
        font-size: 16px;
    }
    .font-18-12{
        font-size: 12px;
    }
    .font-16-12{
        font-size: 12px;
    }
    .font-14-10{
        font-size: 10px;
    }
    .font-12-10{
        font-size: 10px;
    }
    .font-48-24{
        font-size: 24px;
    }
}