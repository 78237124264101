.slide-in-top {
    animation: 0.2s ease-out 0s 1 slideInFromTop;
}
.slide-out-top {
    animation: 0.2s ease-out 0s 1 slideOutFromBottom;
    display: none;
}
.slide-in-right {
    animation: 0.3s ease-out 0s 1 slideInFromRight;
}
.slide-out-right {
    animation: 0.3s ease-out 0s 1 slideOutFromRight;
    display: none;
}
.fade-in-me {
    animation: 0.4s ease-out 0s 0.3 FadeInTemp;
    /* background: #0050C880; */
    backdrop-filter: blur(10px);
}
.fade-out-me {
    animation: 0.4s ease-out 0s 1 FadeOutTemp;
}
@media only screen and (max-width: 1200px) {
  .w-1200-none{
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .w-600-none{
    display: none;
  }
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
      display: flex;
    }
}
@keyframes slideOutFromBottom {
    0% {
      transform: translateY(0);
      display: flex;
    }
    100% {
      transform: translateY(-100%);
    }
}
@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
      display: flex;
    }
}
@keyframes slideOutFromRight {
    0% {
      transform: translateX(0);
      display: flex;
    }
    100% {
      transform: translateX(100%);
    }
}
@keyframes FadeInTemp {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(10px);
    }
}
@keyframes FadeOutTemp {
    0% {
        backdrop-filter: blur(10px);
    }
    100% {
      backdrop-filter: blur(0px);
    }
}