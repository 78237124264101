.App {
  font-family: "Inter", sans-serif;
}


/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00193F;
  border-radius: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.d-row-m-col {
  flex-direction: row;
}
.d-w-30-m-100 {
  width: 30%;
  max-width: 30%;
}
.d-px100-m-px24{
  padding-left: 100px;
  padding-right: 100px;
}
.d-px50-m-px24{
  padding-left: 50px;
  padding-right: 50px;
}
.d-w-65px-m-16px{
  width: 65px;
}
.d-w-50-m-100{
  width: 50%;
}
.d-w-33-m-100{
  width: 33.333%;
}
.navbar-h {
  height: 128px;
}
.font-32{
  font-size: 32px;
}
.font-16{
  font-size: 16px;
}
.d-flex-m-hidden{
  display: flex;
}
.d-hidden-m-flex{
  display: none;
}
.resources-scroll{
  padding-left: 100px;
}

@media only screen and (max-width: 1024px) {
  .resources-scroll{
    padding-left: 24px;
  }
  .d-row-m-col {
    flex-direction: column;
  }
  .d-w-30-m-100 {
    width: 100%;
    max-width: 100%;
  }
  .d-px100-m-px24{
    padding-left: 24px;
    padding-right: 24px;
  }
  .d-px50-m-px24{
    padding-left: 24px;
    padding-right: 24px;
  }
  .d-w-65px-m-16px{
    width: 16px;
  }
  .d-w-50-m-100{
    width: 100%;
  }
  .d-w-33-m-100{
    width: 100%;
  }
  .navbar-h {
    height: 86px;
  }
  .font-32{
    font-size: 12px;
  }
  .font-16{
    font-size: 9px;
  }
  .d-flex-m-hidden{
    display: none;
  }
  .d-hidden-m-flex{
    display: flex;
  }
  
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}